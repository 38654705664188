<template>
  <div class="question js_question js_question_list">
    <Popup v-model="mobShow" position="bottom">
      <Picker title="问题分类" confirm-button-text="完成" visible-item-count="5" show-toolbar :columns="classify"
              :default-index="defaultIndex" item-height="0.84rem"
              @cancel="mobShow = false" @confirm="onConfirm"/>
    </Popup>
    <div class="header" :style="styleObject">
      <div class="header_box">
        <p class="header_title">常见问题</p>
        <div class="header_content">
          <div class="con_search">
            <input type="text" maxlength="30" v-model="searchMsg" placeholder="请输入问题关键字查询">
            <dj-button class="searchbtn" type="dark" @click.native="search">搜索</dj-button>
          </div>
          <div class="con_class">
            <p class="con_class_left">问题分类</p>
            <div class="con_class_right" @click.stop="classRightClcik">
              <p>{{ activeMsg }}</p>
              <i class="font_family icon-common_arrow_down"></i>
            </div>
            <div class="pc_down" v-show="pcDown">
              <ul>
                <li :class="{active:activeMsg===item}" @click="everyClcik(item,index)" v-for="(item,index) in classify"
                    :key="item">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="question_list">
      <ul class="list_ul" v-if="list.length>0">
        <li class="list_every" v-for="item in list" :key="item.problemId">
          <a :href="`/userService/question/details?id=${item.problemId}`" target="_blank">
            <i class="font_family icon-qa_icon"></i>
            <span>{{ item.question }}</span>
          </a>
        </li>
      </ul>
      <Nothing v-else class="nothing"/>
      <dj-paging :pageNumber="page.pageNo" :pageSize="page.pageSize" :total="page.total" :top="top"
                 @current-change="handleCurrentChange"/>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Picker from 'vant/lib/picker';
import Popup from 'vant/lib/popup';
import 'vant/lib/picker/style';
import 'vant/lib/popup/style';
import {selectClassify, selectProblemList} from "@/api/product.js";
import {getPictures} from "@/api/common/index";
import module from "@/const/module";
import metaInfoMixins from "@/utils/mixin/metaInfoMixins";
import utils from "@/utils/utils";

export default {
  name: "question",
  mixins: [metaInfoMixins],
  components: {Picker, Popup},
  data() {
    return {
      mobShow: false,
      pcDown: false,
      activeMsg: "全部",
      searchMsg: "",
      defaultIndex: 0,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      classify: [],
      list: [],
      top: 0,
      styleObject: {},
    };
  },
  created() {
    this.getSelectClassify();
    this.getProblemList();
    this.getPicturesFun();
  },
  mounted() {
    $(document.body).click(() => {
      this.pcDown = false;
    });
    this.onResize();
    // 分页点击的返回上面
    this.getListTop();
  },
  methods: {
    getPicturesFun() {
      let params = {
        productId: this.$store.getters.getProductBasicInfo.productId,
        moduleType: module.question.moduleType
      };
      getPictures(params).then((res) => {
        if (res.success) {
          for (let i of res.data.list) {
            if (i.pictureType === 2) {
              this.styleObject = {
                backgroundImage: `url(${utils.setOssUrl(i.picture)})`
              };
              return;
            }
          }
        }
      });
    },
    getListTop() {
      this.$nextTick(() => {
        const el = $(".js_question_list");
        let top = el.find(".question_list").offset().top;
        this.top = top;
        window.onresize = () => {
          let top = el.find(".question_list").offset().top;
          this.top = top;
        };
      });
    },
    handleCurrentChange(page) {
      this.page.pageNo = page;
      this.getProblemList();
    },
    search() {
      this.page.pageNo = 1;
      this.getProblemList();
    },
    getProblemList() {
      let activeMsg = this.activeMsg;
      if (activeMsg === "全部") {
        activeMsg = "";
      }
      let params = {
        question: this.searchMsg,
        classify: activeMsg,
        ...this.page
      };
      selectProblemList(params).then((res) => {
        if (res.success) {
          this.list = res.data.data;
          this.page.total = res.data.count;
        }
      });
    },
    getSelectClassify() {
      selectClassify().then((res) => {
        if (res.success) {
          res.data.list.unshift("全部");
          this.classify = res.data.list;
        }
      });
    },
    onResize() {
      window.onresize = () => {
        let w = $(window).width();
        if (w > 960) {
          this.mobShow = false;
        } else {
          this.pcDown = false;
        }
      };
    },
    onConfirm(value) {
      this.activeMsg = value;
      this.mobShow = false;
      this.page.pageNo = 1;
      this.getProblemList();
    },
    classRightClcik() {
      let w = $(window).width();
      if (w > 960) {
        this.pcDown = !this.pcDown;
      } else {
        this.mobShow = true;
      }
    },
    everyClcik(e, index) {
      this.defaultIndex = index;
      this.activeMsg = e;
      this.pcDown = false;
      this.page.pageNo = 1;
      this.getProblemList();
    }
  }
};
</script>

<style scoped lang="scss">
.question {
  .header {
    background-image: url("../../assets/images/question_bg.jpg");
    background-position: center;
    background-size: cover;
    .header_box {
      width: 100%;
      height: 427px;
      padding: 0 60px;
      .header_title {
        font-size: 36px;
        color: #FFFFFF;
        line-height: 47px;
        padding: 60px 0 40px 0;
        text-align: center;
        font-weight: bold;
      }
      .header_content {
        .con_search {
          display: flex;
          align-items: center;
          height: 88px;
          background: #FFFFFF;
          input {
            width: 430px;
            padding: 0 24px;
            font-size: 26px;
            box-sizing: border-box;
          }
          .searchbtn {
            /deep/a{
              width: 200px;
              height: 88px;
              font-size: 26px;
            }
          }
        }
        .con_class {
          display: flex;
          align-items: center;
          margin-top: 40px;
          background: #FFFFFF;
          height: 88px;
          position: relative;
          .con_class_left {
            width: 152px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #EEEEEE;
            font-size: 26px;
            color: #333333;
          }
          .con_class_right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px 0 24px;
            min-width: 0;
            flex: auto;
            height: 100%;
            p {
              font-size: 26px;
              color: #333333;
            }
            i {
              font-size: 20px;
              color: #909399;
            }
          }
          .pc_down {
            display: none;
          }
        }
      }
      .pc_down {
        display: none;
      }
    }
  }
  .question_list {
    background: #FFFFFF;
    .list_ul {
      padding-top: 20px;
      .list_every {
        width: 100%;
        height: 120px;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        align-items: center;
        padding: 0 60px;
        a {
          display: flex;
          align-items: center;
          width: 100%;
        }
        i {
          font-size: 24px;
          color: #0064EB;
        }
        span {
          font-size: 28px;
          font-weight: bold;
          color: #333333;
          margin-left: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: bold;
          label {
            font-weight: normal;
          }
        }
      }
    }
    .nothing {
      padding: 40px 0 60px 0;
    }
  }
  /deep/ .van-picker__cancel {
    font-size: 34px;
    color: #3654EA;
  }
  /deep/ .van-picker__title {
    font-size: 34px;
    color: #303133;
  }
  /deep/ .van-picker__confirm {
    font-size: 34px;
    color: #3654EA;
  }
  /deep/ .van-picker-column__item {
    font-size: 30px;
    //height: 84px!important;
  }
  /deep/ .van-picker__frame {
    right: 0;
    left: 0;
    //height: 84px!important;
  }
  /deep/ .van-picker-column__item--selected {
    font-size: 34px;
  }
  /deep/ .van-picker__toolbar {
    height: 84px;
  }
  /deep/ .van-picker__title {
    line-height: 1;
  }
  /deep/ .van-picker__cancel {
    padding: 0 0 0 32px;
  }
  /deep/ .van-picker__confirm {
    padding: 0 32px 0 0;
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .question {
    .header {
      background-image: url("../../assets/images/question_bg.jpg");
      background-position: center;
      background-size: cover;
      .header_box {
        width: 100%;
        height: 400px;
        padding: 0 60px;
        .header_title {
          font-size: 36px;
          color: #FFFFFF;
          line-height: 47px;
          padding: 60px 0 43px 0;
          text-align: center;
          font-weight: bold;
        }
        .header_content {
          .con_search {
            display: flex;
            align-items: center;
            height: 70px;
            background: #FFFFFF;
            input {
              width: 640px;
              padding: 0 24px;
              font-size: 24px;
              box-sizing: border-box;
            }
            .searchbtn {
              /deep/a{
                width: 200px;
                height: 70px;
                font-size: 24px;
              }
            }
          }
          .con_class {
            display: flex;
            align-items: center;
            margin-top: 40px;
            background: #FFFFFF;
            height: 70px;
            position: relative;
            .con_class_left {
              width: 152px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: 1px solid #EEEEEE;
              font-size: 24px;
              color: #333333;
            }
            .con_class_right {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 40px 0 24px;
              min-width: 0;
              flex: auto;
              height: 100%;
              p {
                font-size: 24px;
                color: #333333;
              }
              i {
                font-size: 20px;
                color: #909399;
              }
            }
            .pc_down {
              display: none;
            }
          }
        }
        .pc_down {
          display: none;
        }
      }
    }
    .question_list {
      background: #FFFFFF;
      .list_ul {
        padding-top: 20px;
        .list_every {
          width: 100%;
          height: 120px;
          border-bottom: 1px solid #EEEEEE;
          display: flex;
          align-items: center;
          padding: 0 60px;
          a {
            display: flex;
            align-items: center;
            width: 100%;
          }
          i {
            font-size: 24px;
            color: #0064EB;
          }
          span {
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            margin-left: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
            label {
              font-weight: normal;
            }
          }
        }
      }
      .nothing {
        padding: 40px 0 60px 0;
      }
    }
    /deep/ .van-picker__cancel {
      font-size: 34px;
      color: #3654EA;
    }
    /deep/ .van-picker__title {
      font-size: 34px;
      color: #303133;
    }
    /deep/ .van-picker__confirm {
      font-size: 34px;
      color: #3654EA;
    }
    /deep/ .van-picker-column__item {
      font-size: 30px;
      //height: 84px!important;
    }
    /deep/ .van-picker__frame {
      right: 0;
      left: 0;
      //height: 84px!important;
    }
    /deep/ .van-picker-column__item--selected {
      font-size: 34px;
    }
    /deep/ .van-picker__toolbar {
      height: 84px;
    }
    /deep/ .van-picker__title {
      line-height: 1;
    }
    /deep/ .van-picker__cancel {
      padding: 0 0 0 32px;
    }
    /deep/ .van-picker__confirm {
      padding: 0 32px 0 0;
    }
  }
}
@media (min-width: 961px) {
  .question {
    .header {
      background-image: url("../../assets/images/question_bg.jpg");
      background-position: center;
      background-size: cover;
      .header_box {
        height: 360px;
        padding: 0;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        .header_title {
          font-size: 36px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 47px;
          padding: 91px 0 42px 0;
          text-align: left;
        }
        .header_content {
          display: flex;
          justify-content: space-between;
          .con_search {
            display: flex;
            align-items: center;
            width: 600px;
            height: 50px;
            background: #FFFFFF;
            input {
              width: 420px;
              padding: 0 24px;
              font-size: 16px;
              box-sizing: border-box;
            }
            .searchbtn {
              /deep/a{
                width: 180px;
                height: 50px;
                font-size: 16px;
              }
            }
          }
          .con_class {
            display: flex;
            align-items: center;
            margin-top: 0;
            background: #FFFFFF;
            height: 50px;
            width: 534px;
            position: relative;
            .con_class_left {
              width: 112px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: 1px solid #EEEEEE;
              font-size: 16px;
              color: #333333;
            }
            .con_class_right {
              height: 100%;
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 20px 0 32px;
              min-width: 0;
              flex: auto;
              p {
                font-size: 16px;
                color: #333333;
              }
              i {
                font-size: 12px;
              }
            }
            .pc_down {
              display: block;
              position: absolute;
              top: 54px;
              left: 0;
              width: 100%;
              max-height: 270px;
              background: #FFFFFF;
              box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
              ul {
                display: flex;
                flex-direction: column;
                padding: 10px 0;
                li {
                  width: 100%;
                  height: 50px;
                  background: #FFFFFF;
                  padding: 0 24px;
                  font-size: 16px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  &:hover {
                    background: #F5F7FA;
                  }
                }
                .active {
                  color: #0064eb;
                }
              }
            }
          }
        }
      }
    }
    .question_list {
      background: #FFFFFF;
      .list_ul {
        width: 1200px;
        margin: 0 auto;
        padding-top: 20px;
        .list_every {
          width: 100%;
          height: 80px;
          border-bottom: 1px solid #EEEEEE;
          display: flex;
          align-items: center;
          padding: 0;
          i {
            font-size: 24px;
            color: #0064EB;
          }
          span {
            font-size: 16px;
            font-weight: normal;
            color: #333333;
            margin-left: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
            label {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
</style>
